@media print {
  tfoot {
    display: table-footer-group;
  }
}

.page-footer,
.page-footer-space {
  height: 150px;
}

.page-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e6e6e6;
}

.page {
  page-break-after: always;
}

.image-grid {
  width: 100%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}

.image-grid-image {
  flex-grow: 1;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: 50%;
  position: relative;
  min-height: 100px;
  min-width: 100px;
}

.image-grid-item-wrapper {
  flex: 0 15%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  box-sizing: border-box;
}

.image-grid-item-wrapper :before {
  content: '';
  display: table;
  padding-top: 100%;
}

.custom-file-upload-container {
  overflow: hidden;
  position: relative;
}

.custom-file-upload-container [type='file'] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapbox-logo {
  display: none !important;
}
.windows .target {
  border-radius: '3px';
}

.windows .target.selected {
  background: #dbfcae !important;
  color: #000;
}

@media only screen and (max-width: 1200px) {
  .replyMessageDrawer {
    width: 100% !important;
  }
}

.inline-block {
  display: inline-block !important;
}

.wider-popover {
  max-width: 500px;
  .bp3-popover-content {
    max-width: 500px !important;
  }
}

.segmentAttributeFilterSelect,
.segmentAttributeCuisineFilterSelect {
  .bp3-tag-input-values {
    max-width: 400px;
  }
}

.segmentAttributeFilterSelect {
  .bp3-tag-input-values {
    display: grid !important;
  }
}
